import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage/HomePage.vue'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '*',
    redirect: `/${i18n.locale}/404`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    beforeEnter: (to, from, next) => {
      const locale = `${i18n.locale}`
      if (locale === 'en' || locale === 'de' || locale === 'pl') {
        next()
      } else {
        next({ path: '/', replace: true })
      }
    },
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: '404',
        name: '_404Page',
        component: () => import(/* webpackChunkName: "_404Page" */ '../views/_404Page/_404Page.vue')
      },
      {
        path: 'locations',
        name: 'LocationsPage',
        component: () => import(/* webpackChunkName: "LocationsPage" */ '../views/LocationsPage/LocationsPage.vue')
      },
      {
        path: 'privacy',
        name: 'PrivacyPolicyPage',
        component: () => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../views/PrivacyPolicyPage/PrivacyPolicyPage.vue')
      },
      {
        path: 'impressum',
        name: 'ImpressumPage',
        component: () => import(/* webpackChunkName: "ImpressumPage" */ '../views/ImpressumPage/ImpressumPage.vue')
      },
      {
        path: 'portfolios',
        name: 'PortfoliosPage',
        component: () => import(/* webpackChunkName: "PortfoliosPage" */ '../views/PortfoliosPage/PortfoliosPage.vue')
      },
      {
        path: 'about',
        name: 'AboutPage',
        component: () => import(/* webpackChunkName: "AboutPage" */ '../views/AboutPage/AboutPage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
