<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
    <div v-else class="home">
      <!-- primary section -->
      <section class="primary">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div v-if="primary_title.length > 0" v-html="$prismic.asHtml(this.primary_title)" class="primary__title"></div>
              <div v-if="primary_text.length > 0" v-html="$prismic.asHtml(this.primary_text)" class="primary__text"></div>
              <div v-if="primary_button_label.length > 0" class="primary__box">
                <a class="btn main-button" :href="this.primary_button_url.url">{{ $prismic.richTextAsPlain(this.primary_button_label) }} <img src="@/assets/images/shared/yellow_arrow.svg" alt="arrow" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- focus section -->
      <section data-aos="fade-up" class="focus">
        <div class="container">
          <h2 v-if="focus_title.length > 0" class="main-title">{{ $prismic.richTextAsPlain(this.focus_title) }}</h2>
          <div v-if="focus_gallery.length > 0" class="row">
            <div class="col-6 text-end">
              <div v-for="(item, index) in this.focus_gallery" :key="`${index}`" class="focus__box"><p @mouseover="showImage(index, item.gallery_image.url)" class="focus__text">{{ $prismic.richTextAsPlain(item.gallery_title) }}</p></div>
            </div>
            <div v-bind:style="{ backgroundImage: 'url(' + this.focus_gallery[0].gallery_image.url + ')' }" class="col-6 position-relative focus__gallery">
              <span class="d-none d-lg-block" v-for="(item, index) in this.focus_gallery" :key="`${index}`"><img v-if="imageIndex === index" class="img-fluid focus__image" :src="item.gallery_image.url" alt="focus image" /></span>
              <span v-for="(item, index) in this.focus_gallery" :key="`${index}`"><img v-if="iconIndex === index" class="img-fluid focus__icon" :src="item.gallery_icon.url" alt="icon" /></span>
            </div>
          </div>
        </div>
      </section>
      <!-- features section -->
      <section data-aos="fade-up" v-if="features.length > 0" class="features">
        <div class="container">
          <h2 class="main-title">{{ $prismic.richTextAsPlain(this.features_title) }}</h2>
          <div class="features__box">
            <div class="row">
              <div v-for="(item, index) in this.features" :key="`${index}`" class="col-6 col-lg-3">
                <div class="features__item">
                  <div class="features__image-box">
                    <img :src="item.features_image.url" class="img-fluid features__image" :alt="item.features_image.alt" />
                  </div>
                  <p class="features__text">{{ $prismic.richTextAsPlain(item.features_text) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- services section -->
      <section class="services">
        <div class="container">
          <h2 data-aos="fade-up" v-if="focus_title.length > 0" class="main-title">{{ $prismic.richTextAsPlain(this.services_title) }}</h2>
          <div v-for="(item, index) in this.services_gallery" :key="`${index}`" class="services__box">
            <div v-if="index % 2 === 0" data-aos="fade-up" data-aos-delay="150" data-aos-offset="200" class="row video">
              <div class="col-12 col-lg-6 position-relative">
                <img class="img-fluid services__image" :src="item.services_mobile_image.url" :alt="item.services_mobile_image.alt" />
                <video class="services__video" muted loop>
                  <source :src="require(`@/assets/video/services_video_${index}.mp4`)" type="video/mp4">
                </video>
              </div>
              <div class="col-12 col-lg-6">
                <h3 class="services__title">{{ $prismic.richTextAsPlain(item.service_name) }}</h3>
                <hr class="services__line services__line--right" />
                <p class="services__text">{{ $prismic.richTextAsPlain(item.service_text) }}</p>
              </div>
            </div>
            <div v-else data-aos="fade-up" data-aos-delay="150" data-aos-offset="200" class="row video">
              <div class="col-12 col-lg-6 order-lg-2 position-relative">
                <img class="img-fluid services__image" :src="item.services_mobile_image.url" :alt="item.services_mobile_image.alt" />
                <video class="services__video" muted loop>
                  <source :src="require(`@/assets/video/services_video_${index}.mp4`)" type="video/mp4">
                </video>
              </div>
              <div class="col-12 col-lg-6 order-lg-1">
                <h3 class="services__title services__title--left">{{ $prismic.richTextAsPlain(item.service_name) }}</h3>
                <hr class="services__line services__line--left" />
                <p class="services__text services__text--left">{{ $prismic.richTextAsPlain(item.service_text) }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'HomePage',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      primary_title: null,
      primary_text: null,
      primary_button_label: null,
      primary_button_url: null,
      focus_title: null,
      focus_gallery: null,
      features_title: null,
      features: null,
      services_title: null,
      services_gallery: null,
      imageIndex: null,
      iconIndex: null
    }
  },
  methods: {
    async getContent () {
      await this.$prismic.client.getSingle('homepage', { lang: this.$i18n.lang })
        .then((document) => {
          // primary section
          this.primary_title = document.data.primary_title
          this.primary_text = document.data.primary_text
          this.primary_button_label = document.data.primary_button_label
          this.primary_button_url = document.data.primary_button_url
          // focus section
          this.focus_title = document.data.focus_title
          this.focus_gallery = document.data.focus_gallery
          // features section
          this.features_title = document.data.features_title
          this.features = document.data.features
          // services section
          this.services_title = document.data.services_title
          this.services_gallery = document.data.services_gallery
          this.cmsContentPending = true
        })
    },
    showImage (index, imagePath) {
      const galleryBox = document.querySelector('.focus__gallery')
      galleryBox.style.backgroundImage = `url('${imagePath}')`
      this.imageIndex = index
      this.iconIndex = index
    }
  },
  created () {
    this.imageIndex = 0
    this.iconIndex = 0
    this.getContent()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getContent()
    },
    cmsContentPending: function (newValue) {
      setTimeout(function (scope) {
        const videos = document.querySelectorAll('.video')
        videos.forEach(video => {
          video.addEventListener('mouseover', function () {
            this.getElementsByTagName('video')[0].play()
          })
          video.addEventListener('mouseout', function () {
            this.getElementsByTagName('video')[0].pause()
          })
          video.addEventListener('touchstart', function () {
            this.getElementsByTagName('video')[0].play()
          })
          video.addEventListener('touchend', function () {
            this.getElementsByTagName('video')[0].pause()
          })
        })
      }, 2000, this)
    }
  }
}
</script>

<style lang="scss">
  @import "./HomePage.scss";
</style>
