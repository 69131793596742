import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrismicVue from '@prismicio/vue'
import linkResolver from './prismic/link-resolver'
import i18n from './i18n'
import 'bootstrap'
import AOS from 'aos'

import 'aos/dist/aos.css'
import './styles/custom.scss'
import './styles/main.scss'

Vue.config.productionTip = false
Vue.config.devtools = false
Vue.config.debug = false
Vue.config.silent = true

const accessToken = process.env.VUE_APP_PRISMIC_ACCESS_TOKEN
const endpoint = process.env.VUE_APP_PRISMIC_ENDPOINT

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver
})

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang
  if (!language) {
    language = 'en'
  }

  let lang
  switch (language) {
  case 'en':
    lang = 'en-gb'
    break
  case 'de':
    lang = 'de-de'
    break
  case 'pl':
    lang = 'pl'
    break
  default:
    lang = 'en-gb'
  }

  // set the current language for i18n.
  i18n.locale = language
  // set the current language for Prismic.
  i18n.lang = lang
  next()
})

new Vue({
  created () {
    AOS.init({ duration: 1000 })
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
